<template>
  <div id="company">
    <Header />
    <div class="banner">
      <img src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/other/c_i_pc.jpg" alt="" srcset="" />
    </div>

    <!-- 公司介绍 -->
    <PCCompanyIntroduce />

    <div class="tasantea_banner">
      <img src="../../assets/company/tasnatea_banner.jpg" alt="" />
    </div>

    <div class="title">达人推荐</div>

    <!-- benner -->
    <div class="ability_banner">
      <div class="banner_content">
        <swiper :options="swiperOption">
          <swiper-slide>
            <div
              class="banner_box"
              style="
                background-image: url('https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/x_image6.jpg');
              "
            ></div>
            <div class="model"></div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="banner_box"
              style="
                background-image: url('https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/x_image7.jpg');
              "
            ></div>
            <div class="model"></div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="banner_box"
              style="
                background-image: url('https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/x_image8.jpg');
              "
            ></div>
            <div class="model"></div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="banner_box"
              style="
                background-image: url('https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/x_image9.jpg');
              "
            ></div>
            <div class="model"></div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="banner_box"
              style="
                background-image: url('https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/x_image10.jpg');
              "
            ></div>
            <div class="model"></div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="banner_box"
              style="
                background-image: url('https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/x_image11.jpg');
              "
            ></div>
            <div class="model"></div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="banner_box"
              style="
                background-image: url('https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/x_image12.jpg');
              "
            ></div>
            <div class="model"></div>
          </swiper-slide>
        </swiper>
      

      </div>
    </div>

    <div class="title">品牌IP合作</div>
    <div class="ability_img">
      <img src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/other/ability_img.png" alt="" srcset="" />
    </div>
    <PCConncatUs/>
    <Footer />
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import PCCompanyIntroduce from "@/components/CompanyIntroduce"
import PCConncatUs from "@/components/PCConncatUs.vue";
export default {
  name: "Company",
  components: {
    swiper,
    swiperSlide,
    Header,
    Footer,
    PCCompanyIntroduce,
    PCConncatUs
  },
  props: {},
  setup() {},
  data() {
    return {
      swiperOption: {
        watchSlidesProgress: true,
        slidesPerView: "auto",
        centeredSlides: true,
        loop: true,
        loopedSlides: 5,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        on: {
          progress: function (progress) {
            for (var i = 0; i < this.slides.length; i++) {
              var slide = this.slides.eq(i);
              var slideProgress = this.slides[i].progress;
              var modify = 1;
              if (Math.abs(slideProgress) > 1) {
                modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
              }
              //长度 221是每个swiper-slide宽度的一半
              var translate = slideProgress * modify * 90 + "px";
              var scale = 1 - Math.abs(slideProgress) / 5;
              var zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
              slide.transform(
                "translateX(" + translate + ") scale(" + scale + ")"
              );
              slide.css("zIndex", zIndex);
              slide.css("opacity", 1);
              if (Math.abs(slideProgress) > 3) {
                slide.css("opacity", 0);
              }
            }
          },
          setTransition: function (transition) {
            for (var i = 0; i < this.slides.length; i++) {
              var slide = this.slides.eq(i);
              slide.transition(transition);
            }
          },
        },
      },
    };
  },
  methods: {
    initMaps() {
      let map = new AMap.Map("mapContainer", {
        center: [116.502974, 39.891906],
        zoom: 16,
      });
      var marker = new AMap.Marker({
        position: new AMap.LngLat(116.502974, 39.891906), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
      });
      map.add(marker);
    },
  },
  created() {},
  mounted() {
    this.initMaps();
  },
};
</script>

<style scoped lang="less">
#company {
  @width: 62.5%;
  @min_width: 996px;
  .banner {
    width: 100%;
    margin-top: 64px;
    img {
      width: 100%;
    }
  }
  .tasantea_banner {
    width: @width;
    min-width: @min_width;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .title {
    width: 130px;
    height: 46px;
    background: #45B035;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    line-height: 46px;
    color: #000000;
    margin: 48px auto;
  }
  .ability_banner {
    width: 100%;
    min-height: 739px;
    background: #f5f5f5;
    overflow: hidden;
    .banner_content {
      width: 996px;
      /* width: 341px; */
      height: 614px;
      margin: 62.5px auto;
      position: relative;
      overflow: hidden;
      & /deep/ .swiper-container {
        position: relative;
        overflow: hidden;
      }
      & /deep/ .swiper-container .swiper-slide {
        width: 286px;
        text-align: center;
        line-height: 2em;
      }
      & /deep/ .swiper-container .swiper-slide .model{
        width: 286px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: black;
        opacity: 0.3;
        /* border-radius: 46px; */
      }
      & /deep/ .swiper-container .swiper-slide-active .model{
        opacity: 0 !important;
      }
      .banner_box {
        width: 286px;
        height: 614px;
        background-repeat: no-repeat;
        background-size: cover;
        /* border-radius: 0 0 46px 46px; */
      }
    }    
  }
  .ability_img {
    /* width: 1200px; */
    width: @width;
    min-width: @min_width;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .map_box {
    width: 968px;
    /* min-width: @min_width; */
    /* height: 600px; */
    background: #e9e9df;
    position: relative;
    margin: 60px auto;
    img {
      width: 100%;
      height: 100%;
    }
    .tip_info {
      width: 968px;
      height: 60px;
      background: #45B035;
      position: absolute;
      left: 0;
      bottom: 0;
      .tip_content {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        .tip_item {
          height: 100%;
          font-size: 16px;
          font-weight: 500;
          color: #fffefe;
          line-height: 60px;
          img {
            width: 28px;
            height: 28px;
            vertical-align: middle;
            margin-right: 20px;
          }
        }
      }
    }
  }
}
</style>
